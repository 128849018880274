import {AggregationHourlyWeatherData} from '../models/firestore/aggregation-hourly-weather-data';
import {HourlyArchive} from '../models/archive/hourly-archive';
import * as moment from 'moment';
import {AggregationWeatherData} from '../models/firestore/aggregation-weather-data';
import {Archive} from '../models/archive/archive';
import {WeatherTable} from '../models/weather-table/weather-table';
import {WeatherTableDataFactory} from './weather-table-data-factory';
import {Measurand} from '../models/measurand';
import {Period} from '../models/period';

export class WeatherTableFactory {

  public static ofAggregationHourlyWeatherData(aggregationHourlyWeatherDataList: AggregationHourlyWeatherData[],
                                               hourlyArchive: HourlyArchive,
                                               measurand: Measurand): WeatherTable {
    const weatherTableDescription = new WeatherTable();
    weatherTableDescription.dateColumnName = hourlyArchive.dateColumnName;
    weatherTableDescription.weatherTableData = aggregationHourlyWeatherDataList
      .map(aggregationHourlyWeatherData => WeatherTableDataFactory
        .of(
          WeatherTableFactory.getDateColumnValue(aggregationHourlyWeatherData, hourlyArchive),
          aggregationHourlyWeatherData.totalValues,
          measurand
        )
      );

    return weatherTableDescription;
  }

  public static of(aggregationWeatherDataList: AggregationWeatherData[],
                   archive: Archive,
                   period: Period,
                   measurand: Measurand): WeatherTable {
    const weatherTable = new WeatherTable();
    weatherTable.dateColumnName = archive.dateColumnName;
    weatherTable.weatherTableData = aggregationWeatherDataList
      .filter(data => this.applyCurrentFilter(data, period))
      .map(data => this.toTableRow(data, archive, period, measurand));

    return weatherTable;
  }

  private static applyCurrentFilter(data: AggregationWeatherData, period: Period): boolean {
    return period === Period.TOTAL ||
      (period === Period.DAY && !!data.dayValues) ||
      (period === Period.NIGHT && !!data.nightValues);
  }

  private static toTableRow(data: AggregationWeatherData, archive: Archive, period: Period, measurand: Measurand) {
    return WeatherTableDataFactory.of(
      this.getDateColumnValue(data, archive),
      this.getWeatherValues(period, data),
      measurand
    );
  }

  private static getWeatherValues(period: Period, value: AggregationWeatherData) {
    switch (period) {
      case Period.TOTAL:
        return value.totalValues;
      case Period.DAY:
        return value.dayValues;
      case Period.NIGHT:
        return value.nightValues;
    }
  }

  private static getDateColumnValue(aggregationHourlyWeatherData: AggregationHourlyWeatherData, archive: Archive): string {
    if ('fromHour' in archive && 'toHour' in archive) {
      const hourlyArchive: Archive = archive as Archive;
      const hour1 = moment(aggregationHourlyWeatherData.lastUpdate.toDate())
        .format(hourlyArchive.dateColumnFormat);
      const hour2 = moment(aggregationHourlyWeatherData.lastUpdate.toDate())
        .add(1, 'hour')
        .format(hourlyArchive.dateColumnFormat);
      return hour1 + ' bis ' + hour2 + ' Uhr';
    }
    return moment(aggregationHourlyWeatherData.lastUpdate.toDate())
      .format(archive.dateColumnFormat);
  }
}
