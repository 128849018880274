<div fxLayout="column">

  <div fxLayoutAlign="center">
    <div class="mat-elevation-z4" style="padding: 8px; height: 43px;" fxFlex
         fxLayoutAlign="center" matTooltip="letzte Messung">
      <mat-icon *ngIf="(currentData | async)" svgIcon="update"></mat-icon>
      <span [hidden]="!(currentData | async)" style="margin: 4px;">
          {{(currentData | async)?.lastUpdate.toDate() | date:'shortTime'}} Uhr
      </span>
    </div>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="space-evenly">

    <!-- temperature widget -->
    <div fxLayout="column" class="mat-elevation-z4 widget-box">
      <mat-toolbar color="accent">
        Temperatur
      </mat-toolbar>
      <app-temperature-gauge [temperature]="getTemperature()" class="chart-div"></app-temperature-gauge>
    </div>

    <!-- humidity widget -->
    <div fxLayout="column" class="mat-elevation-z4 widget-box">
      <mat-toolbar color="accent">
        Luftfeuchtigkeit
      </mat-toolbar>
      <app-humidity-chart [humidity]="getHumidity()" class="chart-div"></app-humidity-chart>
    </div>

    <!-- air pressure widget -->
    <div fxLayout="column" class="mat-elevation-z4 widget-box">
      <mat-toolbar color="accent">
        Luftdruck
      </mat-toolbar>
      <app-air-pressure-gauge [airPressure]="getAirPressure()" class="chart-div"></app-air-pressure-gauge>
    </div>
  </div>
</div>

