<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="(isHandset$ | async) === false">
    <mat-toolbar>Menü</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="actual">
        <mat-icon svgIcon="weather-partly-cloudy" style="margin-right: 8px;"></mat-icon>
        Aktuell
      </a>
      <mat-divider style="margin-top: 8px;margin-bottom: 8px;"></mat-divider>
      <a mat-list-item routerLink="hourly">
        <mat-icon svgIcon="clock-outline" style="margin-right: 8px;"></mat-icon>
        Stundenarchiv
      </a>
      <a mat-list-item routerLink="daily">
        <mat-icon svgIcon="calendar" style="margin-right: 8px;"></mat-icon>
        Tagesarchiv
      </a>
      <a mat-list-item routerLink="weekly">
        <mat-icon svgIcon="calendar-week" style="margin-right: 8px;"></mat-icon>
        Wochenarchiv
      </a>
      <a mat-list-item routerLink="monthly">
        <mat-icon svgIcon="calendar-month" style="margin-right: 8px;"></mat-icon>
        Monatsarchiv
      </a>
      <a mat-list-item routerLink="yearly">
        <mat-icon svgIcon="calendar-blank-multiple" style="margin-right: 8px;"></mat-icon>
        Jahresarchiv
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" style="z-index: 1000">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div fxLayoutAlign="center" fxFlex="100" fxHide.lt-sm="true">
        Wetterstation - {{activeRouteName | async}}
      </div>
      <span fxFlex="100" fxHide.sm="true" fxHide.gt-sm="true"></span>

      <!-- measurand menu -->
      <div>
        <button mat-button [matMenuTriggerFor]="measurandMenu">
          <mat-icon svgIcon="gauge"></mat-icon>
          {{dashboardService.measurand}}
        </button>
        <mat-menu #measurandMenu="matMenu">
          <button mat-menu-item *ngFor="let measurand of getAllMeasurandKeys()" (click)="selectMeasurand(measurand)">
            {{getMeasurandValue(measurand)}}
          </button>
        </mat-menu>
      </div>

      <!-- period menu -->
      <div>
        <button mat-button [matMenuTriggerFor]="periodMenu">
          <mat-icon svgIcon="clock"></mat-icon>
          {{dashboardService.period}}
        </button>
        <mat-menu #periodMenu="matMenu">
          <button mat-menu-item *ngFor="let period of getAllPeriodKeys()" (click)="selectPeriod(period)">
            {{getPeriodValue(period)}}
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>
    <section>
      <router-outlet (activate)='onOutletChange($event)'></router-outlet>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
