<table mat-table fxFlex [dataSource]="(weatherTable | async)?.weatherTableData" class="mat-elevation-z8">

  <ng-container matColumnDef="timeColumn">
    <th mat-header-cell *matHeaderCellDef>{{(weatherTable | async)?.dateColumnName}}</th>
    <td mat-cell *matCellDef="let element">{{element.dateColumnValue}}</td>
  </ng-container>

  <ng-container matColumnDef="minimumColumn">
    <th mat-header-cell *matHeaderCellDef>Minimal</th>
    <td mat-cell
        *matCellDef="let element"> {{element.minimumColumnValue | number : '1.1-1'}} {{getMeasurandSymbol()}} </td>
  </ng-container>

  <ng-container matColumnDef="averageColumn">
    <th mat-header-cell *matHeaderCellDef>Durchschnitt</th>
    <td mat-cell
        *matCellDef="let element"> {{element.averageColumnValue | number : '1.1-1'}} {{getMeasurandSymbol()}}</td>
  </ng-container>

  <ng-container matColumnDef="maximumColumn">
    <th mat-header-cell *matHeaderCellDef>Maximal</th>
    <td mat-cell
        *matCellDef="let element"> {{element.maximumColumnValue | number : '1.1-1'}} {{getMeasurandSymbol()}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
