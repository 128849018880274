import {WeatherValues} from '../models/firestore/weather-values';
import {WeatherTableRow} from '../models/weather-table/weather-table-row';
import {Measurand} from '../models/measurand';

export class WeatherTableDataFactory {

  public static of(dateColumnValue: string, weatherValues: WeatherValues, measurand: Measurand): WeatherTableRow {
    if (measurand === Measurand.TEMPERATURE) {
      return new WeatherTableRow(
        dateColumnValue,
        weatherValues.minTemperature,
        weatherValues.averageTemperature,
        weatherValues.maxTemperature
      );
    } else if (measurand === Measurand.HUMIDITY) {
      return new WeatherTableRow(
        dateColumnValue,
        weatherValues.minHumidity,
        weatherValues.averageHumidity,
        weatherValues.maxHumidity
      );
    } else if (measurand === Measurand.AIR_PRESSURE) {
      return new WeatherTableRow(
        dateColumnValue,
        weatherValues.minAirPressure,
        weatherValues.averageAirPressure,
        weatherValues.maxAirPressure
      );
    }
  }
}
