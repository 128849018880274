import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './components/app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {ActualComponent} from './components/dashboard/actual/actual.component';
import {HighchartsChartModule} from 'highcharts-angular';
import {MatDividerModule} from '@angular/material/divider';
import {HourlyArchiveComponent} from './components/dashboard/hourly-archive/hourly-archive.component';
import {DailyArchiveComponent} from './components/dashboard/daily-archive/daily-archive.component';
import {WeeklyArchiveComponent} from './components/dashboard/weekly-archive/weekly-archive.component';
import {MonthlyArchiveComponent} from './components/dashboard/monthly-archive/monthly-archive.component';
import {YearlyArchiveComponent} from './components/dashboard/yearly-archive/yearly-archive.component';
import {HttpClientModule} from '@angular/common/http';
import {TemperatureGaugeComponent} from './components/charts/temperature-gauge/temperature-gauge.component';
import {LoginComponent} from './components/login/login.component';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InformationDialogComponent} from './dialogs/information-dialog/information-dialog.component';

import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from '@angular/common';
import {HumidityChartComponent} from './components/charts/humidity-chart/humidity-chart.component';
import {AirPressureGaugeComponent} from './components/charts/air-pressure-gauge/air-pressure-gauge.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {WeatherDataTableComponent} from './components/dashboard/weather-data-table/weather-data-table.component';
import {MatTableModule} from '@angular/material/table';
import * as moment from 'moment';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ActualComponent,
    HourlyArchiveComponent,
    DailyArchiveComponent,
    WeeklyArchiveComponent,
    MonthlyArchiveComponent,
    YearlyArchiveComponent,
    TemperatureGaugeComponent,
    LoginComponent,
    InformationDialogComponent,
    HumidityChartComponent,
    AirPressureGaugeComponent,
    WeatherDataTableComponent
  ],
  entryComponents: [
    InformationDialogComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase, 'weather-station-webapp'),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatMenuModule,
    LayoutModule,
    HighchartsChartModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatTableModule,
    MatSelectModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
        panelClass: 'custom-dialog-container',
        hasBackdrop: true,
        width: 400
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
    moment.locale('DE');
  }
}
