import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import * as Highcharts from 'highcharts';
import HC_MORE from 'highcharts/highcharts-more';
import solidgauge from 'highcharts/modules/solid-gauge';

HC_MORE(Highcharts);
solidgauge(Highcharts);

@Component({
  selector: 'app-humidity-chart',
  templateUrl: './humidity-chart.component.html',
  styleUrls: ['./humidity-chart.component.css']
})
export class HumidityChartComponent implements OnInit, OnDestroy {


  @Input() humidity: Observable<number>;
  Highcharts: typeof Highcharts;
  chartOptions: Highcharts.Options;
  humiditySubscription: Subscription;
  updateFlag = false;

  constructor() {
  }

  ngOnInit() {
    this.Highcharts = Highcharts;
    this.chartOptions = {
      chart: {
        type: 'solidgauge',
        backgroundColor: 'transparent'
      },

      title: null,

      pane: {
        center: ['50%', '75%'],
        size: '100%',
        startAngle: -90,
        endAngle: 90,
        background: [{
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }]
      },

      exporting: {
        enabled: false
      },

      tooltip: {
        enabled: false
      },

      // the value axis
      yAxis: {
        stops: [
          [1.0, '#28b8e0'] // blue
        ],
        min: 0,
        max: 100,
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        labels: {
          y: 16
        }
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 20,
            borderWidth: 0,
            useHTML: true
          }
        }
      },

      credits: {
        enabled: false
      },

      series: [{
        type: 'solidgauge',
        name: 'Speed',
        data: [],
        dataLabels: {
          format:
            '<div style="text-align:center; margin-bottom: 28px;">' +
            '<span style="font-size:20px">{y} %</span>' +
            '</div>'
        }
      }]
    };

    this.humiditySubscription = this.humidity.subscribe(humidity => {
      // @ts-ignore
      this.chartOptions.series[0].data[0] = humidity;
      this.updateFlag = true;
    });
  }

  ngOnDestroy(): void {
    this.humiditySubscription.unsubscribe();
  }
}
