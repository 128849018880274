import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FirestoreService} from '../../../services/firebase/firestore.service';
import {DashboardService} from '../../../services/dashboard.service';
import {map} from 'rxjs/operators';
import {WeatherTable} from '../../../models/weather-table/weather-table';
import {AggregationWeatherData} from '../../../models/firestore/aggregation-weather-data';
import {WeatherTableFactory} from '../../../util.factories/weather-table-factory';

@Component({
  selector: 'app-daily-archive',
  templateUrl: './daily-archive.component.html',
  styleUrls: ['./daily-archive.component.css']
})
export class DailyArchiveComponent implements OnInit {

  dailyData: Observable<AggregationWeatherData[]>;

  constructor(private firebaseService: FirestoreService,
              private dashboardStateService: DashboardService) {
  }

  ngOnInit() {
    this.dailyData = this.firebaseService.loadDailyData(
      this.dashboardStateService.dailyArchive.fromDate,
      this.dashboardStateService.dailyArchive.toDate
    );
  }

  getWeatherTable(): Observable<WeatherTable> {
    return this.dailyData.pipe(
      map<AggregationWeatherData[], WeatherTable>(value =>
        WeatherTableFactory.of(
          value,
          this.dashboardStateService.dailyArchive,
          this.dashboardStateService.period,
          this.dashboardStateService.measurand
        )
      )
    );
  }
}
