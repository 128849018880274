import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ActualRouteService {

  private readonly actualRouteNameSubject: BehaviorSubject<string>;
  private routeNameMap: object;

  constructor(private router: Router) {
    this.actualRouteNameSubject = new BehaviorSubject(this.router.url);
    this.initRouteNameMap();
  }

  public getActualRouteName() {
    return this.actualRouteNameSubject.asObservable();
  }

  public updateActualRouteName() {

    const routeNameFromMap = this.routeNameMap[this.router.url];
    if (routeNameFromMap === undefined) {
      this.actualRouteNameSubject.next('');
    } else {
      this.actualRouteNameSubject.next(routeNameFromMap);
    }
  }

  private initRouteNameMap() {
    this.routeNameMap = {
      '/dashboard/actual': 'Aktuell',
      '/dashboard/hourly': 'Stundenarchiv',
      '/dashboard/daily': 'Tagesarchiv',
      '/dashboard/weekly': 'Wochenarchiv',
      '/dashboard/monthly': 'Monatsarchiv',
      '/dashboard/yearly': 'Jahresarchiv',
    };
  }
}
