<div fxLayout="column">
  <mat-toolbar color="accent">
    {{data.headline}}
  </mat-toolbar>
  <div style="margin: 16px;" fxLayout="column">
    <div style="line-height: 24px;">
      {{data.message}}
    </div>
    <div fxLayoutAlign="end" style="margin-top: 32px;">
      <button mat-raised-button mat-dialog-close fxFlex="50"> {{data.submitButtonText}}</button>
    </div>
  </div>
</div>
