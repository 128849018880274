<section class="archive" fxLayoutAlign="center" fxFlex>
  <div fxLayout="column">
    <div style="margin: 16px;text-align: justify;" fxFlex><b>Hinweis:</b> Aufgrund eines
      Programmierfehlers wurden die Wetterdaten den Wochen
      nicht
      richtig zugeordnet.
      Im Konkreten heißt das, dass die ersten zwei Tage einer KW fälschlicherweise auf die vorherige KW gerechnet
      wurden.
      Die Daten können nicht mehr nachträglich korrigiert werden und bleiben somit falsch.
    </div>
    <div fxFlex>
      <app-weather-data-table [weatherTable]="getWeatherTable()"></app-weather-data-table>
    </div>
  </div>
</section>

