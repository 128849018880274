import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_MORE from 'highcharts/highcharts-more';
import {Observable, Subscription} from 'rxjs';

HC_MORE(Highcharts);

@Component({
  selector: 'app-temperature-gauge',
  templateUrl: './temperature-gauge.component.html',
  styleUrls: ['./temperature-gauge.component.css']
})
export class TemperatureGaugeComponent implements OnInit, OnDestroy {

  @Input() temperature: Observable<number>;
  Highcharts: typeof Highcharts;
  chartOptions: Highcharts.Options;
  temperatureSubscription: Subscription;
  updateFlag = false;

  constructor() {
  }

  ngOnInit() {
    this.Highcharts = Highcharts;
    this.chartOptions = {
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: 'transparent'
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        gauge: {
          dataLabels: {
            style: {
              fontSize: '20px'
            }
          },
          dial: {
            radius: '75%'
          }
        }
      },
      title: {
        text: undefined
      },

      pane: {
        startAngle: -150,
        endAngle: 150,
        background: [{
          backgroundColor: {
            linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
            stops: [
              [0, '#FFF'],
              [1, '#333']
            ]
          },
          borderWidth: 0,
          outerRadius: '109%'
        }, {
          backgroundColor: {
            linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
            stops: [
              [0, '#333'],
              [1, '#FFF']
            ]
          },
          borderWidth: 1,
          outerRadius: '107%'
        }, {
          // default background
        }]
      },

      // the value axis
      yAxis: {
        min: -50,
        max: 50,

        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 5,
        minorTickPosition: 'inside',
        minorTickColor: '#666',
        tickInterval: 5,

        tickPixelInterval: 15,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: '#383838',
        labels: {
          style: {
            fontSize: '16px'
          },
          step: 2
        },
        title: {
          text: undefined
        },
        plotBands: [{
          from: -50,
          to: -30,
          color: '#1390ef' // blue
        },
          {
            from: -30,
            to: 0,
            color: '#13d0f2' // blue
          },
          {
            from: 0,
            to: 30,
            color: '#DDDF0D' // yellow
          },
          {
            from: 30,
            to: 50,
            color: '#e85a5a' // red
          }]
      },
      tooltip: {
        enabled: false
      },
      series: [{
        type: 'gauge',
        name: 'Temperatur',
        data: [],
        dataLabels: {
          borderWidth: 0,
          format:
            '<div style="text-align:center;">' +
            '<span style="font-size:20px;">{y} <span style="font-size: 24px;">°</span>C</span>' +
            '</div>'
        }
      }]
    };

    this.temperatureSubscription = this.temperature.subscribe(temperature => {
      // @ts-ignore
      this.chartOptions.series[0].data[0] = temperature;
      this.updateFlag = true;
    });
  }

  ngOnDestroy(): void {
    this.temperatureSubscription.unsubscribe();
  }
}
