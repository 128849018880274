import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  redirectUrlAfterLogin: string;

  constructor(public fbAuth: AngularFireAuth) {
  }

  isUserLoggedIn() {
    return this.fbAuth.authState.pipe(
      map(user => {
        return user != null;
      }));
  }

  loginWithEmailAndPassword(email: string, password: string) {
    return this.fbAuth.signInWithEmailAndPassword(email, password);
  }
}
