import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FirestoreService} from '../../../services/firebase/firestore.service';
import {DashboardService} from '../../../services/dashboard.service';
import {map} from 'rxjs/operators';
import {AggregationWeatherData} from '../../../models/firestore/aggregation-weather-data';
import {WeatherTable} from '../../../models/weather-table/weather-table';
import {WeatherTableFactory} from '../../../util.factories/weather-table-factory';

@Component({
  selector: 'app-monthly-archive',
  templateUrl: './monthly-archive.component.html',
  styleUrls: ['./monthly-archive.component.css']
})
export class MonthlyArchiveComponent implements OnInit {

  monthlyData: Observable<AggregationWeatherData[]>;

  constructor(private firebaseService: FirestoreService, private dashboardStateService: DashboardService) {
  }

  ngOnInit() {
    this.monthlyData = this.firebaseService.loadMonthlyData(
      this.dashboardStateService.monthlyArchive.year,
      this.dashboardStateService.monthlyArchive.fromMonth,
      this.dashboardStateService.monthlyArchive.toMonth
    );
  }

  getWeatherTable(): Observable<WeatherTable> {
    return this.monthlyData.pipe(
      map<AggregationWeatherData[], WeatherTable>(value =>
        WeatherTableFactory.of(
          value,
          this.dashboardStateService.monthlyArchive,
          this.dashboardStateService.period,
          this.dashboardStateService.measurand
        )
      )
    );
  }
}
