import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FirestoreService} from '../../../services/firebase/firestore.service';
import {DashboardService} from '../../../services/dashboard.service';
import {map} from 'rxjs/operators';
import {AggregationWeatherData} from '../../../models/firestore/aggregation-weather-data';
import {WeatherTable} from '../../../models/weather-table/weather-table';
import {WeatherTableFactory} from '../../../util.factories/weather-table-factory';

@Component({
  selector: 'app-yearly-archive',
  templateUrl: './yearly-archive.component.html',
  styleUrls: ['./yearly-archive.component.css']
})
export class YearlyArchiveComponent implements OnInit {

  yearlyData: Observable<AggregationWeatherData[]>;

  constructor(private firebaseService: FirestoreService, private dashboardService: DashboardService) {
  }

  ngOnInit() {
    this.yearlyData = this.firebaseService.loadYearlyData(
      this.dashboardService.yearlyArchive.fromYear,
      this.dashboardService.yearlyArchive.toYear
    );
  }

  getWeatherTable(): Observable<WeatherTable> {
    return this.yearlyData.pipe(
      map<AggregationWeatherData[], WeatherTable>(value =>
        WeatherTableFactory.of(
          value,
          this.dashboardService.yearlyArchive,
          this.dashboardService.period,
          this.dashboardService.measurand
        )
      )
    );
  }
}
