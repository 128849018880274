import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {DashboardService} from '../../../services/dashboard.service';
import {WeatherTable} from '../../../models/weather-table/weather-table';
import {Measurand} from '../../../models/measurand';
import {Period} from '../../../models/period';

@Component({
  selector: 'app-weather-data-table',
  templateUrl: './weather-data-table.component.html',
  styleUrls: ['./weather-data-table.component.css']
})
export class WeatherDataTableComponent implements OnInit {

  constructor(private dashboardService: DashboardService) {
  }

  private static readonly measurandSymbols: Map<Measurand, string> = new Map<Measurand, string>([
      [Measurand.TEMPERATURE, '°C'],
      [Measurand.HUMIDITY, '%'],
      [Measurand.AIR_PRESSURE, 'hPa']
    ]
  );

  @Input() weatherTable: Observable<WeatherTable>;
  @Input() hideMinAndMax: boolean;
  displayedColumns: string[] = [];

  ngOnInit(): void {
    this.displayedColumns.push('timeColumn');
    if (!this.hideMinAndMax) {
      this.displayedColumns.push('minimumColumn');
    }
    this.displayedColumns.push('averageColumn');
    if (!this.hideMinAndMax) {
      this.displayedColumns.push('maximumColumn');
    }
  }

  getMeasurandSymbol() {
    return WeatherDataTableComponent.measurandSymbols.get(this.dashboardService.measurand);
  }
}


