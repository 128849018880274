import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {DashboardService} from '../../../services/dashboard.service';
import {FirestoreService} from '../../../services/firebase/firestore.service';
import {map} from 'rxjs/operators';
import {AggregationHourlyWeatherData} from '../../../models/firestore/aggregation-hourly-weather-data';
import {WeatherTable} from '../../../models/weather-table/weather-table';
import {WeatherTableFactory} from '../../../util.factories/weather-table-factory';

@Component({
  selector: 'app-hourly-archive',
  templateUrl: './hourly-archive.component.html',
  styleUrls: ['./hourly-archive.component.css']
})
export class HourlyArchiveComponent implements OnInit {

  hourlyData: Observable<AggregationHourlyWeatherData[]>;

  constructor(private firebaseService: FirestoreService,
              private dashboardStateService: DashboardService) {
  }

  ngOnInit() {
    this.hourlyData = this.firebaseService.loadHourlyData(
      this.dashboardStateService.hourlyArchive.date,
      this.dashboardStateService.hourlyArchive.fromHour,
      this.dashboardStateService.hourlyArchive.toHour,
    );
  }

  getWeatherTable(): Observable<WeatherTable> {
    return this.hourlyData.pipe(
      map<AggregationHourlyWeatherData[], WeatherTable>(aggregationHourlyWeatherData =>
        WeatherTableFactory.ofAggregationHourlyWeatherData(
          aggregationHourlyWeatherData,
          this.dashboardStateService.hourlyArchive,
          this.dashboardStateService.measurand
        )
      )
    );
  }
}
