import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ActualComponent} from './components/dashboard/actual/actual.component';
import {HourlyArchiveComponent} from './components/dashboard/hourly-archive/hourly-archive.component';
import {DailyArchiveComponent} from './components/dashboard/daily-archive/daily-archive.component';
import {YearlyArchiveComponent} from './components/dashboard/yearly-archive/yearly-archive.component';
import {MonthlyArchiveComponent} from './components/dashboard/monthly-archive/monthly-archive.component';
import {WeeklyArchiveComponent} from './components/dashboard/weekly-archive/weekly-archive.component';
import {LoginComponent} from './components/login/login.component';
import {LoggedOutGuard} from './guards/loggedout/logged-out.guard';
import {LoggedInGuard} from './guards/loggedin/logged-in.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedOutGuard]
  },
    {
      path: 'dashboard',
      component: DashboardComponent,
      canActivate: [LoggedInGuard],
      children: [
        {
          path: '',
          redirectTo: 'actual',
          pathMatch: 'full'
        },
        {
          path: 'actual',
          component: ActualComponent
        },
        {
          path: 'hourly',
          component: HourlyArchiveComponent
        },
        {
          path: 'daily',
          component: DailyArchiveComponent
        },
        {
          path: 'weekly',
          component: WeeklyArchiveComponent
        },
        {
          path: 'monthly',
          component: MonthlyArchiveComponent
        },
        {
          path: 'yearly',
          component: YearlyArchiveComponent
        },
      ]
    },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
  ]
;

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
