export class WeatherTableRow {
  dateColumnValue: string;
  minimumColumnValue: number;
  averageColumnValue: number;
  maximumColumnValue: number;

  constructor(dateColumnValue: string,
              minimumColumnValue: number,
              averageColumnValue: number,
              maximumColumnValue: number) {
    this.dateColumnValue = dateColumnValue;
    this.minimumColumnValue = minimumColumnValue;
    this.averageColumnValue = averageColumnValue;
    this.maximumColumnValue = maximumColumnValue;
  }
}
