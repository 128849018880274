import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {CurrentWeatherData} from '../../models/firestore/current-weather-data';
import {AggregationHourlyWeatherData} from '../../models/firestore/aggregation-hourly-weather-data';
import {AggregationWeatherData} from '../../models/firestore/aggregation-weather-data';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private firestore: AngularFirestore) {
  }

  public loadCurrentWeatherData(): Observable<CurrentWeatherData | undefined> {
    console.debug('load current data');
    return this.firestore
      .collection('weather_station')
      .doc<CurrentWeatherData>('S001')
      .valueChanges().pipe(shareReplay(1));
  }

  public loadHourlyData(date: Date, fromHour: number = 0, toHour: number = 23): Observable<AggregationHourlyWeatherData[]> {
    console.debug('load hourly data for date', date);
    return this.firestore
      .collection('weather_station')
      .doc('S001')
      .collection('aggregation_daily')
      .doc(moment(date).format('YYYY-MM-DD'))
      .collection<AggregationHourlyWeatherData>('aggregation_hourly', ref => ref
        .where('lastUpdate', '>=', moment(date).hour(fromHour).toDate())
        .where('lastUpdate', '<=', moment(date).hour(toHour).toDate())
      )
      .valueChanges().pipe(shareReplay(1));
  }

  public loadDailyData(fromDate: Date, toDate: Date, limit: number = 100): Observable<AggregationWeatherData[]> {
    console.debug('load daily data from', fromDate, 'to', toDate, 'limit', limit);
    return this.firestore
      .collection('weather_station')
      .doc('S001')
      .collection<AggregationWeatherData>('aggregation_daily', ref => ref
        .where('lastUpdate', '>=', fromDate)
        .where('lastUpdate', '<=', toDate)
        .limit(limit)
      )
      .valueChanges().pipe(shareReplay(1));
  }

  public loadWeeklyData(year: number, fromWeek: number, toWeek: number, limit: number = 53): Observable<AggregationWeatherData[]> {
    console.debug('load weekly data for year', year, 'from week', fromWeek, 'to week', toWeek);
    const date1 = moment()
      .year(year)
      .week(fromWeek)
      .startOf('week')
      .toDate();
    const date2 = moment()
      .year(year)
      .week(toWeek)
      .startOf('week')
      .toDate();
    return this.firestore
      .collection('weather_station')
      .doc('S001')
      .collection('aggregation_yearly')
      .doc(String(year))
      .collection<AggregationWeatherData>('aggregation_weekly', ref => ref
        .where('lastUpdate', '>=', date1)
        .where('lastUpdate', '<', date2)
        .limit(limit)
      )
      .valueChanges().pipe(shareReplay(1));
  }

  public loadMonthlyData(year: number, fromMonth: number, toMonth: number, limit: number = 12): Observable<AggregationWeatherData[]> {
    console.debug('load monthly data for year', year, 'from month', fromMonth, 'to month', toMonth);
    const momentYearDate = moment().year(year);
    return this.firestore
      .collection('weather_station')
      .doc('S001')
      .collection('aggregation_yearly')
      .doc(String(year))
      .collection<AggregationWeatherData>('aggregation_monthly', ref => ref
        .where('lastUpdate', '>=', momentYearDate.month(fromMonth).toDate())
        .where('lastUpdate', '<=', momentYearDate.month(toMonth).toDate())
        .limit(limit)
      )
      .valueChanges().pipe(shareReplay(1));
  }

  public loadYearlyData(fromYear: number, toYear: number, limit: number = 100): Observable<AggregationWeatherData[]> {
    console.debug('load yearly data from year', fromYear, 'to year', toYear);
    return this.firestore
      .collection('weather_station')
      .doc('S001')
      .collection<AggregationWeatherData>('aggregation_yearly', ref => ref
        .where('lastUpdate', '>=', moment().year(fromYear).toDate())
        .where('lastUpdate', '<=', moment().year(toYear).toDate())
        .limit(limit)
      )
      .valueChanges().pipe(shareReplay(1));
  }
}
