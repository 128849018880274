import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {HourlyArchive} from '../models/archive/hourly-archive';
import {DailyArchive} from '../models/archive/daily-archive';
import {WeeklyArchive} from '../models/archive/weekly-archive';
import {MonthlyArchive} from '../models/archive/monthly-archive';
import {YearlyArchive} from '../models/archive/yearly-archive';
import {Period} from '../models/period';
import {Measurand} from '../models/measurand';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  period: Period = Period.TOTAL;
  measurand: Measurand = Measurand.TEMPERATURE;

  hourlyArchive: HourlyArchive = {
    dateColumnName: 'Stunde des Tages',
    dateColumnFormat: 'H',
    date: new Date(),
    fromHour: 0,
    toHour: 23
  };
  dailyArchive: DailyArchive = {
    dateColumnName: 'Datum',
    dateColumnFormat: 'DD.MM.YY',
    fromDate: moment(new Date()).subtract(1, 'M').toDate(),
    toDate: new Date()
  };
  weeklyArchive: WeeklyArchive = {
    dateColumnName: 'Kalenderwoche',
    dateColumnFormat: '[KW] w',
    year: moment().year(),
    fromWeek: 1,
    toWeek: moment().week() // + 1 // todo fix bug -> raspberry is adding values to wrong KW. That's why I omit last KW -> kw+1
  };
  monthlyArchive: MonthlyArchive = {
    dateColumnName: 'Monat',
    dateColumnFormat: 'MMMM',
    year: moment().year(),
    fromMonth: 0,
    toMonth: moment().month()
  };
  yearlyArchive: YearlyArchive = {
    dateColumnName: 'Jahr',
    dateColumnFormat: 'YYYY',
    fromYear: moment().subtract(10, 'y').year(),
    toYear: moment().year()
  };

  constructor() {
  }
}
