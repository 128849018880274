import {Component, OnInit} from '@angular/core';
import {FirestoreService} from '../../../services/firebase/firestore.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CurrentWeatherData} from '../../../models/firestore/current-weather-data';

@Component({
  selector: 'app-actual',
  templateUrl: './actual.component.html',
  styleUrls: ['./actual.component.css']
})
export class ActualComponent implements OnInit {

  currentData: Observable<CurrentWeatherData | undefined>;

  constructor(private firebaseService: FirestoreService) {
  }

  ngOnInit() {
    this.currentData = this.firebaseService.loadCurrentWeatherData();
  }

  getTemperature() {
    return this.currentData.pipe(
      map(value => value.temperature)
    );
  }

  getHumidity() {
    return this.currentData.pipe(
      map(value => value.humidity)
    );
  }

  getAirPressure() {
    return this.currentData.pipe(
      map(value => value.airPressure)
    );
  }
}
