import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthService} from '../../services/auth/auth.service';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {InformationDialogComponent} from '../../dialogs/information-dialog/information-dialog.component';

class LoginData {

  email: string;
  password: string;

  constructor(email: string = '', password: string = '') {
    this.email = email;
    this.password = password;
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginData: LoginData;
  showFormErrors = false;

  constructor(private authService: AuthService, private router: Router, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.loginData = new LoginData();
  }

  /**
   * Check if the login-form is valid. If not, a the error message will be shown. Otherwise the log-in will be executed for Firebase.
   * @param loginForm reference to the Login-Form
   */
  onSubmit(loginForm: NgForm) {
    if (loginForm.invalid) {
      console.error('Login-Form is invalid. Can\'t login with credentials to Firebase', this.loginData);
      this.showFormErrors = true;
      return;
    }
    this.showFormErrors = false;
    console.log('logging in with credentials to Firebase for user', this.loginData.email);
    this.authService.loginWithEmailAndPassword(this.loginData.email, this.loginData.password)
      .then(r => this.router.navigate(['/dashboard']))
      .catch(reason => {
        console.error('Could not login user', reason);
        this.openFailedToLoginDialog();
      });
  }

  private openFailedToLoginDialog() {
    this.dialog.open(
      InformationDialogComponent,
      {
        data: {
          headline: 'Anmeldung fehlgeschlagen',
          message: 'E-Mail und Passwort stimmen nicht überein. Der Login war nicht erfolgreich. Bitte überprüfen Sie Ihre Anmeldedaten.',
          submitButtonText: 'Okay'
        }
      }
    );
  }
}
