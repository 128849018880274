import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {ActualRouteService} from '../../services/route/actual-route.service';
import {DashboardService} from '../../services/dashboard.service';
import {Measurand} from '../../models/measurand';
import {keyframes} from '@angular/animations';
import {Period} from '../../models/period';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  activeRouteName: Observable<string>;

  constructor(private breakpointObserver: BreakpointObserver,
              private actualRouteService: ActualRouteService,
              public dashboardService: DashboardService) {
  }

  ngOnInit(): void {
    this.activeRouteName = this.actualRouteService.getActualRouteName();
  }

  onOutletChange($event: any) {
    this.actualRouteService.updateActualRouteName();
  }

  getAllMeasurandKeys(): string[] {
    return Object.keys(Measurand);
  }

  getMeasurandValue(measurandKey: string) {
    return Measurand[measurandKey];
  }

  selectMeasurand(measurandKey: string) {
    this.dashboardService.measurand = Measurand[measurandKey];
  }

  getPeriodValue(periodKey: string) {
    return Period[periodKey];
  }

  getAllPeriodKeys() {
    return Object.keys(Period);
  }

  selectPeriod(periodKey: string) {
    this.dashboardService.period = Period[periodKey];
  }
}
