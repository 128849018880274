<div fxLayout="row" fxLayoutAlign="center" style="padding: 16px;">
  <div fxLayout="column" class="mat-elevation-z4" style="width: 550px;">
    <mat-toolbar color="primary">
      Wetterstation - Login
    </mat-toolbar>

    <!--Login Form-->
    <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)" fxLayout="column" style="padding: 16px;">

      <!-- Input Email -->
      <mat-form-field [hideRequiredMarker]="true">
        <input matInput
               email type="email"
               [(ngModel)]="loginData.email"
               placeholder="E-Mail"
               name="email"
               #email="ngModel"
               maxlength="320"
               required>
        <mat-error *ngIf="showFormErrors && email.invalid">Bitte geben sie eine gültige E-Mail ein!</mat-error>
      </mat-form-field>

      <!-- Input Password -->
      <mat-form-field [hideRequiredMarker]="true" style="margin-top: 8px;">
        <input matInput
               type="password"
               [(ngModel)]="loginData.password"
               placeholder="Passwort"
               name="password"
               #password="ngModel"
               maxlength="50"
               required>
        <mat-error *ngIf="showFormErrors && password.invalid">Bitte geben sie Ihr Passwort ein!</mat-error>
      </mat-form-field>

      <!-- Submit Button-->
      <div fxFlex="40" fxLayoutAlign="center" style="margin-top: 24px; margin-bottom: 8px;">
        <button type="submit" mat-raised-button color="accent" style="width: 50%;">Login</button>
      </div>
    </form>
  </div>
</div>
